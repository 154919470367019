import React from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
// import { ReactComponent as InstagramIcon } from '../icons/instagram.svg';
// import { ReactComponent as TwitterIcon } from '../icons/twitter.svg';
import './Footer.sass';

function Footer() {
    return (
        <footer className='footer'>
            <Container className='footer__container'>
                <div className='footer__col'>
                    <Link to='/'>
                        <img
                            alt='Logo'
                            className='footer__logo'
                            src='https://vamanossf.s3.us-west-1.amazonaws.com/logo-2.png'
                        />
                        {/*<h2>VAMANOS</h2>*/}
                    </Link>
                </div>
                <div className='footer__col'>
                    <a aria-label='email' href='mailto:info@vamanossf.org'>
                        <Button className='footer__button' variant='link'>info@vamanossf.org</Button>
                    </a>
                </div>
                {/*<div className='footer__social'>*/}
                {/*    <TwitterIcon className='footer__icon' />*/}
                {/*    <InstagramIcon className='footer__icon' />*/}
                {/*</div>*/}
            </Container>
        </footer>
    );
}

export default Footer;
